body {
  background-color: #000 !important;
  color:#fff !important;
}

.hunting-list-avatar img {
    border-radius: 2px !important;
}

.fade-in-item {
  animation: fadeIn 2s;
  background-color: '#6236D0'
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}



@keyframes heartBeat {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.heartBeat {
  display: inline-block;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.heartBeat.active {
  animation-name: heartBeat;
}